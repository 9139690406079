import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["overlay"]

  connect() {
    document.querySelector('body').classList.add('modal-active')
  }

  disconnect() {
    document.querySelector('body').classList.remove('modal-active')
  }

  close() {
    let elem = this.overlayTarget
    if(elem) {
      elem.parentNode.removeChild(elem);
    }
  }
}