import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["tab", "tabPanel"]

  connect() {
    let selectedTab = this.data.get("active-tab")

    this.activate(selectedTab)
  }

  activate(tab) {
    let itemsToActivate = [this.tabTargets, this.tabPanelTargets]

    itemsToActivate.forEach(items => {
      items.forEach(element => {
        let keys = element.dataset.key.split("|")

        if(keys.includes(tab)) {
          element.classList.add("active")
        } else {
          element.classList.remove("active")
        }
      });
    })
  }

  activateTab(e) {
    let prevent = e.target.parentElement.dataset.prevent || e.target.dataset.prevent || "false"
    if (prevent === "true") {
      e.preventDefault()
    }

    let key = e.target.parentElement.dataset.key || e.target.dataset.key
    this.activate(key)
  }
}