import { Controller } from "stimulus"

export default class extends Controller {
  static targets = ["pane"]

  connect() {
    let show = this.data.get("status") === "show"

    if (show) {
      this.showPane()
    } else {
      this.hidePane()
    }
  }

  showPane() {
    this.paneTarget.classList.add("active")
  }

  hidePane() {
    this.paneTarget.classList.remove("active")
  }
}