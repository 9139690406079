function modal(content) {
  return  `<div class="fixed z-50 overflow-auto bg-smoke-light flex w-full h-full" data-controller="modal" data-target="modal.overlay" data-action="click->modal#close">
            <div class="relative bg-white w-full max-w-md m-auto flex-col flex rounded">
              <span class="absolute top-0-t right-0 p-1 text-neutral" data-action="click->modal#close">
                <svg class="h-12 w-12 fill-current text-grey hover:text-grey-darkest" role="button"
                  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                  <title>Close</title>
                  <path
                    d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
                </svg>
              </span>
              <dib class="p-8">
               ${content}
              </dib>
            </div>
          </div>`
}

function submittedLeadModal() {
  let content = `<h1>Thank you for your interest!</h1>
                 <p>We will launch this service in the near future.</p>
                 <p>We will let you know more in the next following months.</p>`
  return modal(content)
}

function error(error) {
  return modal(error)
}

export default {
  modal: modal,
  submittedLeadModal: submittedLeadModal,
  error: error
}